import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e3ffbcf0 = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _4e2b1dc4 = () => interopDefault(import('../pages/AgreementCrowdSale.vue' /* webpackChunkName: "pages/AgreementCrowdSale" */))
const _0fdfa085 = () => interopDefault(import('../pages/artists/index.vue' /* webpackChunkName: "pages/artists/index" */))
const _36bb729f = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _fbcb7464 = () => interopDefault(import('../pages/collections/index.vue' /* webpackChunkName: "pages/collections/index" */))
const _993f7f2c = () => interopDefault(import('../pages/crowdsale.vue' /* webpackChunkName: "pages/crowdsale" */))
const _2622ad35 = () => interopDefault(import('../pages/Disclaimer.vue' /* webpackChunkName: "pages/Disclaimer" */))
const _214e577e = () => interopDefault(import('../pages/help/index.vue' /* webpackChunkName: "pages/help/index" */))
const _1657e6de = () => interopDefault(import('../pages/Privacy.vue' /* webpackChunkName: "pages/Privacy" */))
const _27f3e780 = () => interopDefault(import('../pages/sandbox/index.vue' /* webpackChunkName: "pages/sandbox/index" */))
const _0a8335e8 = () => interopDefault(import('../pages/service-agreement.vue' /* webpackChunkName: "pages/service-agreement" */))
const _13b20cdd = () => interopDefault(import('../pages/Terms.vue' /* webpackChunkName: "pages/Terms" */))
const _71263cff = () => interopDefault(import('../pages/Terms_202110290648.vue' /* webpackChunkName: "pages/Terms_202110290648" */))
const _74c0c003 = () => interopDefault(import('../pages/admin/administrators/index.vue' /* webpackChunkName: "pages/admin/administrators/index" */))
const _fa425976 = () => interopDefault(import('../pages/admin/artists/index.vue' /* webpackChunkName: "pages/admin/artists/index" */))
const _6358f77e = () => interopDefault(import('../pages/admin/clients/index.vue' /* webpackChunkName: "pages/admin/clients/index" */))
const _0e7bda4d = () => interopDefault(import('../pages/admin/dashboard/index.vue' /* webpackChunkName: "pages/admin/dashboard/index" */))
const _329851b7 = () => interopDefault(import('../pages/admin/projects/index.vue' /* webpackChunkName: "pages/admin/projects/index" */))
const _6f7496bf = () => interopDefault(import('../pages/archivedPages/me.vue' /* webpackChunkName: "pages/archivedPages/me" */))
const _103d2d8e = () => interopDefault(import('../pages/archivedPages/publicproject.vue' /* webpackChunkName: "pages/archivedPages/publicproject" */))
const _52940449 = () => interopDefault(import('../pages/archivedPages/signin.vue' /* webpackChunkName: "pages/archivedPages/signin" */))
const _672a5abf = () => interopDefault(import('../pages/archivedPages/signup.vue' /* webpackChunkName: "pages/archivedPages/signup" */))
const _771e5b28 = () => interopDefault(import('../pages/archivedPages/upload.vue' /* webpackChunkName: "pages/archivedPages/upload" */))
const _291b5bf2 = () => interopDefault(import('../pages/archivedPages/uploadmany.vue' /* webpackChunkName: "pages/archivedPages/uploadmany" */))
const _49408cae = () => interopDefault(import('../pages/sandbox/Helpers/CheckSupportedBrowser.js' /* webpackChunkName: "pages/sandbox/Helpers/CheckSupportedBrowser" */))
const _2be30f41 = () => interopDefault(import('../pages/sandbox/Helpers/Config.js' /* webpackChunkName: "pages/sandbox/Helpers/Config" */))
const _3d01dcac = () => interopDefault(import('../pages/sandbox/Helpers/GetDirectoriesRandomFiles.js' /* webpackChunkName: "pages/sandbox/Helpers/GetDirectoriesRandomFiles" */))
const _3ef791be = () => interopDefault(import('../pages/sandbox/Helpers/GetFirstImageDimension.js' /* webpackChunkName: "pages/sandbox/Helpers/GetFirstImageDimension" */))
const _625767d8 = () => interopDefault(import('../pages/sandbox/Helpers/GetImageFromFile.js' /* webpackChunkName: "pages/sandbox/Helpers/GetImageFromFile" */))
const _4d21d7b6 = () => interopDefault(import('../pages/sandbox/Helpers/ReadFileSync.js' /* webpackChunkName: "pages/sandbox/Helpers/ReadFileSync" */))
const _1d69fe16 = () => interopDefault(import('../pages/sandbox/Helpers/UploadFiles.js' /* webpackChunkName: "pages/sandbox/Helpers/UploadFiles" */))
const _083a78a0 = () => interopDefault(import('../pages/admin/artists/_id/index.vue' /* webpackChunkName: "pages/admin/artists/_id/index" */))
const _1cf3b922 = () => interopDefault(import('../pages/admin/projects/_id/index.vue' /* webpackChunkName: "pages/admin/projects/_id/index" */))
const _3504d157 = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _339fc9b9 = () => interopDefault(import('../pages/collections/_id/index.vue' /* webpackChunkName: "pages/collections/_id/index" */))
const _7155314c = () => interopDefault(import('../pages/help/_category/index.vue' /* webpackChunkName: "pages/help/_category/index" */))
const _36304b88 = () => interopDefault(import('../pages/profile/_profileId/index.vue' /* webpackChunkName: "pages/profile/_profileId/index" */))
const _10ff316a = () => interopDefault(import('../pages/collections/_id/purchase.vue' /* webpackChunkName: "pages/collections/_id/purchase" */))
const _04218d6d = () => interopDefault(import('../pages/collections/_id/widget.vue' /* webpackChunkName: "pages/collections/_id/widget" */))
const _eff97ae4 = () => interopDefault(import('../pages/profile/_profileId/collections/index.vue' /* webpackChunkName: "pages/profile/_profileId/collections/index" */))
const _063e24e8 = () => interopDefault(import('../pages/profile/_profileId/vault.vue' /* webpackChunkName: "pages/profile/_profileId/vault" */))
const _3987d528 = () => interopDefault(import('../pages/assets/_collectionId/_assetId/index.vue' /* webpackChunkName: "pages/assets/_collectionId/_assetId/index" */))
const _f755b8e6 = () => interopDefault(import('../pages/c/_id/_wallet.vue' /* webpackChunkName: "pages/c/_id/_wallet" */))
const _1b7ba270 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _e3ffbcf0,
    name: "admin"
  }, {
    path: "/AgreementCrowdSale",
    component: _4e2b1dc4,
    name: "AgreementCrowdSale"
  }, {
    path: "/artists",
    component: _0fdfa085,
    name: "artists"
  }, {
    path: "/blog",
    component: _36bb729f,
    name: "blog"
  }, {
    path: "/collections",
    component: _fbcb7464,
    name: "collections"
  }, {
    path: "/crowdsale",
    component: _993f7f2c,
    name: "crowdsale"
  }, {
    path: "/Disclaimer",
    component: _2622ad35,
    name: "Disclaimer"
  }, {
    path: "/help",
    component: _214e577e,
    name: "help"
  }, {
    path: "/Privacy",
    component: _1657e6de,
    name: "Privacy"
  }, {
    path: "/sandbox",
    component: _27f3e780,
    name: "sandbox"
  }, {
    path: "/service-agreement",
    component: _0a8335e8,
    name: "service-agreement"
  }, {
    path: "/Terms",
    component: _13b20cdd,
    name: "Terms"
  }, {
    path: "/Terms_202110290648",
    component: _71263cff,
    name: "Terms_202110290648"
  }, {
    path: "/admin/administrators",
    component: _74c0c003,
    name: "admin-administrators"
  }, {
    path: "/admin/artists",
    component: _fa425976,
    name: "admin-artists"
  }, {
    path: "/admin/clients",
    component: _6358f77e,
    name: "admin-clients"
  }, {
    path: "/admin/dashboard",
    component: _0e7bda4d,
    name: "admin-dashboard"
  }, {
    path: "/admin/projects",
    component: _329851b7,
    name: "admin-projects"
  }, {
    path: "/archivedPages/me",
    component: _6f7496bf,
    name: "archivedPages-me"
  }, {
    path: "/archivedPages/publicproject",
    component: _103d2d8e,
    name: "archivedPages-publicproject"
  }, {
    path: "/archivedPages/signin",
    component: _52940449,
    name: "archivedPages-signin"
  }, {
    path: "/archivedPages/signup",
    component: _672a5abf,
    name: "archivedPages-signup"
  }, {
    path: "/archivedPages/upload",
    component: _771e5b28,
    name: "archivedPages-upload"
  }, {
    path: "/archivedPages/uploadmany",
    component: _291b5bf2,
    name: "archivedPages-uploadmany"
  }, {
    path: "/sandbox/Helpers/CheckSupportedBrowser",
    component: _49408cae,
    name: "sandbox-Helpers-CheckSupportedBrowser"
  }, {
    path: "/sandbox/Helpers/Config",
    component: _2be30f41,
    name: "sandbox-Helpers-Config"
  }, {
    path: "/sandbox/Helpers/GetDirectoriesRandomFiles",
    component: _3d01dcac,
    name: "sandbox-Helpers-GetDirectoriesRandomFiles"
  }, {
    path: "/sandbox/Helpers/GetFirstImageDimension",
    component: _3ef791be,
    name: "sandbox-Helpers-GetFirstImageDimension"
  }, {
    path: "/sandbox/Helpers/GetImageFromFile",
    component: _625767d8,
    name: "sandbox-Helpers-GetImageFromFile"
  }, {
    path: "/sandbox/Helpers/ReadFileSync",
    component: _4d21d7b6,
    name: "sandbox-Helpers-ReadFileSync"
  }, {
    path: "/sandbox/Helpers/UploadFiles",
    component: _1d69fe16,
    name: "sandbox-Helpers-UploadFiles"
  }, {
    path: "/admin/artists/:id",
    component: _083a78a0,
    name: "admin-artists-id"
  }, {
    path: "/admin/projects/:id",
    component: _1cf3b922,
    name: "admin-projects-id"
  }, {
    path: "/blog/:slug",
    component: _3504d157,
    name: "blog-slug"
  }, {
    path: "/collections/:id",
    component: _339fc9b9,
    name: "collections-id"
  }, {
    path: "/help/:category",
    component: _7155314c,
    name: "help-category"
  }, {
    path: "/profile/:profileId",
    component: _36304b88,
    name: "profile-profileId"
  }, {
    path: "/collections/:id/purchase",
    component: _10ff316a,
    name: "collections-id-purchase"
  }, {
    path: "/collections/:id/widget",
    component: _04218d6d,
    name: "collections-id-widget"
  }, {
    path: "/profile/:profileId?/collections",
    component: _eff97ae4,
    name: "profile-profileId-collections"
  }, {
    path: "/profile/:profileId?/vault",
    component: _063e24e8,
    name: "profile-profileId-vault"
  }, {
    path: "/assets/:collectionId?/:assetId",
    component: _3987d528,
    name: "assets-collectionId-assetId"
  }, {
    path: "/c/:id?/:wallet?",
    component: _f755b8e6,
    name: "c-id-wallet"
  }, {
    path: "/",
    component: _1b7ba270,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
